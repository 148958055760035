//
// News page 
// --------------------------------------------------


.news {
    .row;
    margin-bottom: @line-height*2;
    img {
        margin-bottom: @line-height;
        opacity: 0.6;
    }
    img:hover {
        -moz-transition: all 0.5s ease;
        -ms-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
        opacity: 1;
    }
}

.news-thumbnail {
    .make-xs-column(6);
    .make-md-column(4);
    margin-bottom: @line-height*2;
}

@media (max-width: 399px) {
    .news-thumbnail {
        display: none;
    }  
    .news-thumbnail:first-child {
        display: block;
        width: 100%;
    }
}


@media (min-width: 400px) and (max-width: @screen-xs-max) {
    .news-thumbnail:first-child {
        display: block;
        width: 50%;
    }
    .news-thumbnail:last-of-type {
        display: none;
    }
}

@media only screen and (min-width: @screen-xs-max) and (max-width: @screen-sm-max)  {
    .news-thumbnail:first-child {
        display: block;
        width: 50%;
    }
    .news-thumbnail:nth-child(2) {
        display: block;
        width: 50%;
    }
    .news-thumbnail:last-child {
        display: none;
    }
}

.news-thumbnail img {
    width: 100%;
}

.news-page .news-thumbnail {
    .make-md-column(8);
    padding-left: 0;
}

@media (max-width: @screen-sm-max) {
    .news-thumbnail {
        margin-bottom: @line-height*3;
    }
    .news {
        margin-bottom: @line-height*2;
    }
}

#news-page div {
    border-bottom: solid 1px @brand-primary;
}

#news-page div:last-child {
    border-bottom: none;
}

#news-page img {
    max-width:100%;
    margin-bottom:@line-height*2;
}

.news-other {
    .make-md-column(4);
    text-align: center;
}

.news-other::-webkit-scrollbar {
    width: 1em;
}

.news-other::-webkit-scrollbar-thumb {
    background-color: @brand-primary;
    border-radius: 4px;
}

.news-other::-webkit-scrollbar-track {
    background-color: @gray-light;
    border-radius: 4px;
}

.news-other ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0.5em;
}

.news-other h2 {
    margin-top: 0;
    font-size: 1.5em;
}

.news-other h3 {
    font-size: 1em;
}