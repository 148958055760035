//
// Footer
//

#footer {
    margin-top: @line-height*3;
    background-color: @navbar-default-bg;
    .box-shadow(@shadow: 0 -1px 2px 1px rgba(0,0,0,0.3));
    h3 {
        font-size: 1.2em;
    }
    h4 {
        font-size: 1em;
        font-weight: initial;
    }
}

#copyright {
    margin-top:@line-height;
    text-align: center;
    .text-muted;
    font-size: 0.8em;
}

#footer .row > div {
    .make-xs-column(6);
}


#footer span {
    .glyphicon;
    margin-right:1em;
}

.adress {
    .glyphicon-home;
}

.telephone {
    .glyphicon-earphone;
}

.fax {
    .glyphicon-print;
}

.tool-shop {
    .glyphicon-wrench;
}

.recycling {
    .glyphicon-refresh;
}

.mail {
    .glyphicon-envelope;
}

.newsletter {
    .glyphicon-inbox;
}

@media (min-width: @screen-sm-max) {
    #footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 200px;
    }
    body {
        margin-bottom: calc(200px + 4em);
    }

    html {
        min-height: 100%;
        position: relative;
    }
}