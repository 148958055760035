//
// About pages
// --------------------------------------------------

.about-text, #news-page {
    text-align: justify;
    font-size:@long-body;
    line-height:@long-line-height;
}

@media (max-width:@screen-xs-max) {
    .about-text, #news-page {
        text-align: left;
    }
}

.about .col-sm-6 {
    padding-right: @line-height * 2;
}

.about .row > .col-sm-6:nth-child(2) {
    padding-left: @line-height * 2;
}

.about .row .col-sm-6 h2 {
    margin-top:@line-height*1.5;
    color:@brand-primary;
    margin-bottom:@line-height;
}

@media (max-width:@screen-xs-max) {
    .about .col-sm-6 {
        padding-right: 15px;
    }

    .about .row > .col-sm-6:nth-child(2) {
        padding-left: 15px;
    }

    .about .row .col-sm-6 h2 {
        margin-top:@line-height*2;
    }
}

.about .panel {
    font-style: italic;
    font-size: 1em;
}

.timeline ul, .references ul {
    line-height: @line-height*1.5;
}

.timeline li, .references li {
    margin-bottom: @line-height;
}

.timeline ul {
    padding-left: 0;
}

.timeline li {
    list-style: none;
}