//
// Header
//

.navbar {
    .navbar-default();
    .navbar-fixed-top();
}

@media (max-width:@screen-lg-min) {
    .navbar-header {
        width:100%;
    }
}

@media (max-width:@screen-xs-max) {
    .navbar-nav {
        margin-bottom: 0;
        margin-top: 0;
    }
    .navbar-collapse {
        height: @navbar-collapse-max-height!important;
    }
    .navbar-brand img {
        max-width: 160px;
    }
}

.navbar-toggle {
    margin-right: 0;
}

// Second level menu items 

.dropdown-menu {
    border-radius:0;
    font-size: 1em;
}

.dropdown-submenu {
    position:relative;
}
.dropdown-submenu>.dropdown-menu {
    top:0;
    left:100%;
    margin-top:-6px;
    margin-left:-1px;
}

@media (max-width:@screen-xs-max) {
    .dropdown-submenu>.dropdown-menu {
        display: block;
        padding-left: 10px;
    }
}
    
.dropdown-submenu:hover>.dropdown-menu {
    display:block;
}

.dropdown-submenu>a:after {
    display:block;
    content:" ";
    float:right;
    width:0;
    height:0;
    border-color:transparent;
    border-style:solid;
    border-width:5px 0 5px 5px;
    border-left-color:#cccccc;
    margin-top:5px;
    margin-right:-10px;
}

.dropdown-submenu:hover>a:after {
    border-left-color:#ffffff;
}

.dropdown-submenu.pull-left {
    float:none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
    left:-100%;
    margin-left:10px;
}

// Search Form

.navbar #search-form input.form-control {
    display: inline;
    width:80%;
    margin-left: 15px;
    margin-bottom: @line-height;
    font-size: 1em;
} 

#search-form {
    display: table;
    position: relative;
    top: 13px;
    a {
        vertical-align: text-bottom;
        text-decoration: none;
    }
}

.navbar-nav.navbar-right:last-child {
    margin-right: 15px;
}

.glyphicon-search {
    font-size: 14px;
}