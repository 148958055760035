//
// Download pages
// --------------------------------------------------

.download-page h2 {
    font-size: 1.5em;
    margin:0;
    margin-bottom:0.5em;
}

.download-page>.row {
    margin:@line-height -15px;
    margin-bottom: @line-height*2;
}