.home {
    .thumbnails-container, .thumb:last-of-type, .tiles>div:last-child, .news-thumbnail, .news {
        margin-bottom: 0;
    }
    .top-products h3 {
        margin-top:@line-height*2!important;
    }
}

@media (min-width:@screen-sm-min) {
    .home {
        .content {
            margin-top: 0;
            padding-top: 0;
        }
    }
}

@media (max-width:@screen-sm-max) {
    .home {
        h3:first-of-type {
            margin-top:0;
            padding-top:0;
        }
    }
}


.top-products .thumbnails-container > .row > div {
    .make-sm-column(6);
    .make-md-column(4);
}

.top-products .thumb a h4 {
    height: inherit;
}